import { EditorContext } from '@tiptap/react'

import { BiasScannerContext } from 'context/BiasScannerContext'
import { useContext, useEffect } from 'react'

const EditorContent = ({ children }: any) => {
  const { isScanning } = useContext(BiasScannerContext)
  const { editor } = useContext(EditorContext)

  useEffect(() => {
    editor?.setEditable(!isScanning)
  }, [editor, isScanning])

  return <>{children}</>
}

export default EditorContent
