import React from 'react'
import './App.css'
import Routes from './routes'
import { ApolloProvider } from '@apollo/client'

function App({ client }: any) {
  return (
    <div className='App'>
      <ApolloProvider client={client}>
        <Routes />
      </ApolloProvider>
    </div>
  )
}

export default App
