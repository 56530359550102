import { Box, Typography, styled } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import React from 'react'

interface ResourcesProps {
  articleType: string
  text: string
}

const ResourceContainer = styled(Box)`
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  background: #fff;

  padding: 24px;
`

const Resources = ({ text, articleType }: ResourcesProps) => {
  return (
    <ResourceContainer>
      <Box
        sx={{
          borderRadius: '8px',
          border: '1px solid #E5E5E5',
          padding: '16px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Typography variant='body2'>
          <b>How to:</b> Writing an inclusive job description
        </Typography>
        <ChevronRightIcon
          onClick={() => {
            window.open(
              'https://www.datocms-assets.com/54533/1672633222-ht01_howtowriteaninclusivejobdescription_platform.pdf',
              '_blank'
            )
          }}
          sx={{
            background: '#F2F3F3',
            borderRadius: '50%',
            padding: '8px'
          }}
        />
      </Box>
      <Box
        sx={{
          borderRadius: '8px',
          border: '1px solid #E5E5E5',
          padding: '16px',
          marginTop: '16px'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Typography variant='body2'>
            <b>How to:</b> Build a brand that’s proactive and inclusive
          </Typography>
          <ChevronRightIcon
            onClick={() => {
              window.open(
                'https://www.datocms-assets.com/54533/1672633387-ht06_howtobuildaproactiveandinclusivebrand.pdf',
                '_blank'
              )
            }}
            sx={{
              background: '#F2F3F3',
              borderRadius: '50%',
              padding: '8px'
            }}
          />
        </Box>
      </Box>
    </ResourceContainer>
  )
}

export default Resources
