import { Categories } from 'pages/biasScanner/contant'
import { v4 as uuidv4 } from 'uuid'

const getHighlightCoordinates = (term: any, handleHighlightClickRef: any) => {
  const walk = document.createTreeWalker(
    document.querySelector('.editor')!,
    NodeFilter.SHOW_TEXT,
    null
  )
  let count = 0
  let node: any = null
  let startOffset: any = null
  let endOffset: any = null

  while ((node = walk.nextNode())) {
    let currentIndex = 0
    while (
      (startOffset = node
        .nodeValue!.toLowerCase()
        .indexOf(term.input.toLowerCase(), currentIndex)) !== -1
    ) {
      endOffset = startOffset + term.input.length
      const range = document.createRange()
      range.setStart(node, startOffset)
      range.setEnd(node, endOffset)
      const rects = range.getClientRects()
      count++
      if (rects.length) {
        Array.from(rects).forEach(rect => {
          createShadowDomElem(rect, handleHighlightClickRef, term, range)
        })
      }
      currentIndex = endOffset
    }
  }
  return count
}

const getColorsFromCategory = (category: string) => {
  const { labelColor, backgroundColor, highlightBackgroundColor } =
    Categories.find(item => item.label === category)!
  return { labelColor, backgroundColor, highlightBackgroundColor }
}
const createShadowDomElem = (
  coords: any,
  handleHighlightClickRef: any,
  term: any,
  range: any
) => {
  const vditorContent = document.querySelector('.scroller')
  const vditorContentRect = vditorContent?.getBoundingClientRect()

  let singleHighlight = document.createElement('div')

  singleHighlight.dataset.trigger = 'bias'
  singleHighlight.dataset.input = term.input
  singleHighlight.dataset.explanation = term.explanation
  singleHighlight.dataset.replacement = term.replacement
  singleHighlight.dataset.category = term.feCategory
  singleHighlight.dataset.id = uuidv4()

  const adjustedLeft =
    coords.left -
    (vditorContentRect?.left || 0) +
    (vditorContent?.scrollLeft || 0)
  const adjustedTop =
    coords.top - (vditorContentRect?.top || 0) + (vditorContent?.scrollTop || 0)
  const { labelColor, highlightBackgroundColor } = getColorsFromCategory(
    term.feCategory ?? term.category
  )
  singleHighlight.style.cssText = `position: absolute; left: ${adjustedLeft}px; top: ${adjustedTop}px; width: ${coords.width}px; height: ${coords.height}px; border-bottom: 2px solid ${labelColor};  z-index: 9999; cursor: pointer; visibility: visible; pointer-events: all;`
  singleHighlight.style.backgroundColor = highlightBackgroundColor

  if (vditorContent) {
    singleHighlight.addEventListener('click', event => {
      if (handleHighlightClickRef.current) {
        handleHighlightClickRef.current(event, range)
      }
    })

    vditorContent.appendChild(singleHighlight)
  }
}

const clearShadowDomElements = () => {
  const previousHighlights = document?.querySelectorAll('[data-trigger="bias"]')

  previousHighlights?.forEach(highlight => {
    highlight.remove()
  })
}

const removeShadowDomElementsByTerm = (termText: string) => {
  const vditorContent = document.querySelector('.scroller')!
  const termHighlights = vditorContent?.querySelectorAll(
    `[data-input="${termText}"]`
  )
  termHighlights?.forEach(highlight => {
    highlight.remove()
  })
}

const removeShadowDomElementById = (id: string) => {
  const vditorContent = document.querySelector('.scroller')!
  const termHighlights = vditorContent?.querySelectorAll(`[data-id="${id}"]`)
  const count = termHighlights?.length
  termHighlights?.forEach(highlight => {
    highlight.remove()
  })
  return count
}

export {
  getHighlightCoordinates,
  clearShadowDomElements,
  removeShadowDomElementsByTerm,
  removeShadowDomElementById,
  getColorsFromCategory
}
