export enum Decision {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  SKIPPED = 'SKIPPED'
}

export type BiasScannerData = {
  scan: (content: string) => void
  popupAnchorEl: null | HTMLElement
  explaination: string
  replaceTerm: string
  closePopup: () => void
  handleReplacement: (replaceTerm: string) => void
  isScanning: boolean
  isScanningStarted: boolean
  selectedInput: string
  selectedCategory: string
  adjustHighlight: () => void
  removeCurrentHighlight: () => void
  totalNumberOfHighlights: number
  removeAllHighlights: () => void
  submitBiasDecision: (decision: Decision) => void
}
