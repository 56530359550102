import { Color } from '@mathison-inc/components'

export const Categories = [
  {
    label: 'Feminine',
    labelColor: Color.eggplant300,
    backgroundColor: Color.eggplant200,
    highlightBackgroundColor: 'rgba(66,12,164,0.2)'
  },
  {
    label: 'Masculine',
    labelColor: Color.turquoise300,
    backgroundColor: Color.turquoise200,
    highlightBackgroundColor: 'rgba(27, 129, 131,0.2)'
  },
  {
    label: 'Accessibility & Clarity',
    labelColor: Color.red300,
    backgroundColor: Color.red200,
    highlightBackgroundColor: 'rgba(255, 104, 104,0.2)'
  },
  {
    label: 'Jargon or Buzzwords',
    labelColor: Color.yellow300,
    backgroundColor: Color.yellow200,
    highlightBackgroundColor: 'rgba(255, 224, 62,0.2)'
  },
  {
    label: 'Exclusionary',
    labelColor: Color.iris300,
    backgroundColor: Color.iris200,
    highlightBackgroundColor: 'rgba(55, 95, 198,0.2)'
  },
  {
    label: 'Other',
    labelColor: Color.tangerine300,
    backgroundColor: Color.tangerine200,
    highlightBackgroundColor: 'rgba(252, 166, 37,0.2)'
  }
]

export const TestData = [
  {
    input: 'an analytical mind',
    replacement: 'delete',
    explanation:
      'Language that emphasizes "analytical skills" or "critical thinking" can be easily misunderstood, we recommend adding more specific detail or removing.',
    category: Categories[2].label
  },

  {
    input: 'CFO',
    replacement: 'Critical Financial Opulence (CFO)',
    explanation:
      'Acronyms are not always clear to candidates, be sure to only use if you are certain they will be understood by everyone.',
    category: Categories[1].label
  },
  {
    input: 'decide',
    replacement: 'conclude',
    explanation:
      "This is a masculine coded word. It's okay to have a few gender coded words, but if you have too many, it may deter some job seekers.",
    category: Categories[1].label
  },
  {
    input: 'excellent communication',
    replacement: 'delete',
    explanation:
      'Language that emphasizes "communication skills" or "presentation skills" can be easily misunderstood and reduce the diversity of your applicant pool. Consider removing or providing more context around the specific job responsibilities.',
    category: Categories[2].label
  },
  {
    input: 'for the organization',
    replacement: 'for us',
    explanation: 'Candidates are more likely to respond to "us" language.',
    category: Categories[5].label
  },
  {
    input: 'is a plus',
    replacement: 'delete',
    explanation:
      "Preferred qualifications can send a mixed message to job seekers about what's necessary for them to apply. We recommend removing anything that's not 100% necessary.",
    category: Categories[4].label
  },
  {
    input: 'MBA',
    replacement: 'delete',
    explanation:
      "Educational attainment isn't strongly predictive of performance in a role, and may introduce bias. We recommend only educational requirements that are absolutely necessary.",
    category: Categories[3].label
  },
  {
    input: 'of the organization',
    replacement: 'of us',
    explanation: 'Candidates are more likely to respond to "us" language.',
    category: Categories[2].label
  },
  {
    input: 'organizational skills',
    replacement: 'delete',
    explanation:
      'Language that emphasizes the need for "strong organizational skills" is typically already implied and can be easily misunderstood, reducing the diversity of your applicant pool.',
    category: Categories[1].label
  },
  {
    input: 'Strong leadership',
    replacement: 'delete',
    explanation:
      'Language that references "leadership" can be easily misunderstood and reduces overall applicant diversity. Consider removing or adding more context around the specific job responsibilities and work environment.',
    category: Categories[2].label
  },
  {
    input: 'The company',
    replacement: 'we',
    explanation: 'Candidates are more likely to respond to "us" language.',
    category: Categories[1].label
  },
  {
    input: 'The ideal candidate',
    replacement: 'You',
    explanation: 'Candidates are more likely to respond to "you" language.',
    category: Categories[0].label
  },
  {
    input: 'The ideal candidate will be',
    replacement: 'You are',
    explanation: 'Candidates are more likely to respond to "you" language.',
    category: Categories[1].label
  },
  {
    input: 'the use of',
    replacement: 'use',
    explanation:
      "Consider simplifying this phrase to improve your job's clarity.",
    category: Categories[4].label
  }
]
