import styled from '@emotion/styled'
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Typography
} from '@mui/material'
import { EditorProvider } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'

import { useContext, useState } from 'react'

import { BiasScannerContext } from 'context/BiasScannerContext'
import copyWithFormatByClassName from 'utils/richTextHandler'
import EditorContent from './EditorContent'
import ReplacementPopper from './ReplacementPopper'
import { Decision } from 'context/BiasScannerContext/model'

const extensions = [
  StarterKit.configure({
    history: false
  })
]
const EditorContainer = styled.div({
  backgroundColor: 'white',
  position: 'relative',
  borderRadius: '8px',
  border: '1px solid #E5E5E5',
  padding: '32px',
  '& .scroller': {
    maxHeight: '660px',
    minHeight: '210px',
    overflowY: 'scroll',
    padding: '1px 24px',
    textAlign: 'left',
    position: 'relative',
    border: '1px solid #ACADAF',
    borderRadius: '8px',
    margin: '0 auto',
    ':focus-within': { border: '2px solid #050B0E', padding: '0 23px' }
  },
  '& .editor': { height: '568px' },
  '& .editor:focus-visible': { outline: 'none' }
})

const BiasScanner = () => {
  const [content, setContent] = useState(
    localStorage.getItem('biasScannerContent') || ''
  )

  const {
    scan,
    popupAnchorEl,
    closePopup,
    isScanning,
    adjustHighlight,
    submitBiasDecision
  } = useContext(BiasScannerContext)

  return (
    <EditorContainer className='editorContainer'>
      <Box
        sx={{
          paddingBottom: '16px'
        }}
      >
        <Button
          variant='contained'
          onClick={() => {
            scan(content)
          }}
          disabled={isScanning}
          startIcon={isScanning ? <CircularProgress size='20px' /> : undefined}
        >
          {isScanning ? 'Scanning...' : 'Scan'}
        </Button>
        {!isScanning && (
          <Button
            variant='contained'
            onClick={async () => {
              copyWithFormatByClassName('.editor')
            }}
            color='secondary'
            sx={{ float: 'right' }}
            disabled={isScanning}
            startIcon={
              isScanning ? <CircularProgress size='20px' /> : undefined
            }
          >
            Copy text
          </Button>
        )}
      </Box>

      <div className='scroller' tabIndex={0}>
        <EditorProvider
          extensions={extensions}
          content={content}
          editorProps={{ attributes: { class: 'editor' } }}
          editable={!isScanning}
          autofocus={false}
          onUpdate={({ editor }) => {
            const content = editor.getHTML()
            setContent(content)
            localStorage.setItem('biasScannerContent', content)
            adjustHighlight()
          }}
        >
          <EditorContent>
            <ReplacementPopper />
          </EditorContent>
        </EditorProvider>
      </div>
      <Backdrop
        invisible
        open={!!popupAnchorEl}
        onClick={() => {
          closePopup()
          submitBiasDecision(Decision.SKIPPED)
        }}
      />
      <Box
        sx={{
          paddingTop: '16px'
        }}
      >
        <Typography>{`${
          content.replace(/<\/?[^>]+(>|$)/g, '').length
        } character${
          content.replace(/<\/?[^>]+(>|$)/g, '').length > 1 ? 's' : ''
        }`}</Typography>
      </Box>
    </EditorContainer>
  )
}

export default BiasScanner
