import useBiasScanner from 'hook/useBiasScanner'
import React, {
  createContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import {
  clearShadowDomElements,
  getHighlightCoordinates,
  removeShadowDomElementById
} from 'utils/highlightHelper'
import { BiasScannerData, Decision } from './model'
import { v4 as uuid } from 'uuid'

let highlightTerms: any[] = []
export const BiasScannerContext = createContext<BiasScannerData>({
  scan: (_: string) => {},
  popupAnchorEl: null,
  explaination: '',
  replaceTerm: '',
  closePopup: () => {},
  handleReplacement: (_: string) => {},
  isScanning: false,
  totalNumberOfHighlights: 0,
  isScanningStarted: false,
  selectedInput: '',
  selectedCategory: '',
  adjustHighlight: () => {},
  removeCurrentHighlight: () => {},
  removeAllHighlights: () => {},
  submitBiasDecision: (_: Decision) => {}
})

export const BiasScannerProvider = ({ children }: any): JSX.Element => {
  const [isScanning, setIsScanning] = useState(false)
  const handleHighlightClickRef: any = useRef()

  const [scanId, setScanId] = useState('')
  const [isScanningStarted, setIsScanningStarted] = useState(false)
  const [popupAnchorEl, setPopupAnchorEl] = useState<null | HTMLElement>(null)
  const [totalNumberOfHighlights, setTotalNumberOfHighlights] = useState(0)
  const { detectBiasAi, detectBias, submitFeedback } = useBiasScanner()
  const [explaination, setExplaination] = useState('')
  const [replaceTerm, setReplaceTerm] = useState('')
  const [selectedInput, setSelectedInput] = useState('')
  const [selectedCategory, setSelectedCategory] = useState<string>('')
  const [selectedHighlightId, setSelectedHighlightId] = useState<string>('')
  const [selectedHighlightRange, setSelectedHighlightRange] = useState<Range>()

  handleHighlightClickRef.current = (
    event: React.MouseEvent<HTMLElement>,
    range: Range
  ) => {
    setPopupAnchorEl(event.currentTarget)
    setSelectedCategory(event.currentTarget.dataset.category || '')
    setSelectedInput(event.currentTarget.dataset.input || '')
    setReplaceTerm(event.currentTarget.dataset.replacement || '')
    setExplaination(event.currentTarget.dataset.explanation || '')
    setSelectedHighlightId(event.currentTarget.dataset.id || '')
    setSelectedHighlightRange(range)
    const selection = window.getSelection()
    if (selection) {
      selection.removeAllRanges()
      selection.addRange(range)
    }
  }
  useEffect(() => {
    if (isScanning) {
      clearShadowDomElements()
      highlightTerms = []
    }
  }, [isScanning])

  const store: BiasScannerData = useMemo(() => {
    const closePopup = () => {
      setPopupAnchorEl(null)
    }
    const handleReplacement = (replaceTerm: string) => {
      if (selectedHighlightRange) {
        selectedHighlightRange.deleteContents()
        const textNode = document.createTextNode(replaceTerm)
        selectedHighlightRange.insertNode(textNode)
        removeShadowDomElementById(selectedHighlightId)
        setPopupAnchorEl(null)
        setTotalNumberOfHighlights(totalNumberOfHighlights - 1)
      }
    }

    const scan = async (content: string) => {
      setIsScanning(true)
      setIsScanningStarted(true)
      clearShadowDomElements()
      const requestId = uuid()
      const { data: biasResult } = await detectBias({
        variables: {
          input: {
            text: content,
            requestId: requestId
          }
        }
      })
      const result = biasResult?.detectBias?.biasResults
      const { scanId } = biasResult?.detectBias
      setScanId(scanId)
      const containsAnotherTerm = (term: string, otherTerm: string) => {
        if (term.toLowerCase() === otherTerm.toLowerCase()) return false
        const splitTerm = term.toLowerCase().split(/\W+/)
        return splitTerm.every(word => otherTerm.toLowerCase().includes(word))
      }
      const filteredResult = result.filter((item: any) => {
        return !result.some((otherItem: any) =>
          containsAnotherTerm(item.input, otherItem.input)
        )
      })

      let index = 0
      let totalHighlights = 0
      const highlightNextTerm = () => {
        if (index < filteredResult.length) {
          const term = filteredResult[index]
          highlightTerms.push(term)
          const count = getHighlightCoordinates(term, handleHighlightClickRef)
          totalHighlights += count
          setTotalNumberOfHighlights(totalHighlights)
          index++

          setTimeout(highlightNextTerm, 1000)
        } else {
        }
      }

      highlightNextTerm()

      const fetchData: any = async (retryCount: number = 0) => {
        try {
          const { data } = await detectBiasAi({
            variables: {
              input: {
                text: content,
                requestId: requestId
              }
            }
          })
          const errors = data?.detectBiasAi?.errors
          if (errors && errors.length) {
            const error = errors[0]
            if (error.isRetryable && retryCount < 2) {
              await new Promise(r => setTimeout(r, 2000)) // Wait 2 seconds before retrying
              return fetchData(retryCount + 1)
            } else {
              setIsScanning(false)
            }
          }
          return data
        } catch (error) {
          setIsScanning(false)
        }
      }
      const data = await fetchData()

      const aiResult = data?.detectBiasAi?.biasResults
      let aiIndex = 0

      const highlightNextAiTerm = () => {
        if (aiIndex < aiResult.length) {
          const term = aiResult[aiIndex]
          const count = getHighlightCoordinates(term, handleHighlightClickRef)
          highlightTerms.push(term)

          aiIndex++
          totalHighlights += count
          setTotalNumberOfHighlights(totalHighlights)
          setTimeout(highlightNextAiTerm, 1000)
        } else {
          setIsScanning(false)
        }
      }
      highlightNextAiTerm()
    }

    const adjustHighlight = () => {
      clearShadowDomElements()

      highlightTerms.forEach(term => {
        getHighlightCoordinates(term, handleHighlightClickRef)
      })
    }

    const removeCurrentHighlight = () => {
      const count = removeShadowDomElementById(selectedHighlightId)
      highlightTerms = highlightTerms.filter(
        item => item.input !== selectedInput
      )
      setTotalNumberOfHighlights(totalNumberOfHighlights - count)
    }

    const removeAllHighlights = () => {
      clearShadowDomElements()
      highlightTerms = []
      setTotalNumberOfHighlights(0)
    }

    const submitBiasDecision = async (decision: Decision) => {
      submitFeedback({
        variables: {
          input: {
            scanId: scanId,
            biasText: selectedInput,
            replacement: replaceTerm,
            decision: decision
          }
        }
      })
    }

    return {
      isScanning,
      scan,
      popupAnchorEl,
      explaination,
      replaceTerm,
      closePopup,
      handleReplacement,
      totalNumberOfHighlights,
      isScanningStarted,
      selectedInput,
      selectedCategory,
      adjustHighlight,
      removeCurrentHighlight,
      removeAllHighlights,
      submitBiasDecision
    }
  }, [
    isScanning,
    popupAnchorEl,
    explaination,
    replaceTerm,
    totalNumberOfHighlights,
    isScanningStarted,
    selectedInput,
    selectedCategory,
    selectedHighlightRange,
    selectedHighlightId,
    detectBias,
    detectBiasAi,
    submitFeedback,
    scanId
  ])
  return (
    <BiasScannerContext.Provider value={store}>
      {children}
    </BiasScannerContext.Provider>
  )
}
