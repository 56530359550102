import React from 'react'
import ReactDOM from 'react-dom'
import { createRoot } from 'react-dom/client'
import './index.css'
import App from './App'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from '@mathison-inc/components'

const render = (props: any) => {
  const root = createRoot(
    props.container.querySelector('#tandem-root') as HTMLElement
  )
  const { client } = props
  root.render(
    <Router>
      <ThemeProvider theme={theme}>
        <App client={client} />
      </ThemeProvider>
    </Router>
  )
}

if (!(window as any).__POWERED_BY_QIANKUN__) {
  render({})
}

export const bootstrap = async () => {}

export const mount = async (props: any) => {
  render(props)
}

export const unmount = async (props: any) => {
  const { container } = props

  ReactDOM.unmountComponentAtNode(
    container
      ? container.querySelector('#tandem-root')
      : document.querySelector('#tandem-root')
  )
}
export const update = async (props: any) => {
  console.log('update props from main framework', props)
  render(props)
}
