import { Typography, Box, Grid } from '@mui/material'
import React from 'react'
import Scanner from './Scanner'
import Suggestions from './Suggestions'
import Resources from 'components/Resources/Resources'

const BiasScannerContainer = () => {
  return (
    <Box>
      <Typography variant='h1' marginBottom='24px'>
        Bias Scanner
      </Typography>
      <Grid container spacing={2}>
        <Grid item width='810px'>
          <Scanner />
        </Grid>
        <Grid item>
          <Box>
            <Suggestions />
            <Grid container marginTop='24px'>
              <Typography variant='h5' marginBottom='24px'>
                Resources
              </Typography>
            </Grid>

            <Grid item width='320px' container>
              <Resources
                articleType='How to'
                text='How to: Writing an inclusive job description'
              />
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default BiasScannerContainer
