import { gql, useMutation } from '@apollo/client'
const SCAN_AI = gql`
  mutation ($input: DetectBiasAiInput!) {
    detectBiasAi(input: $input) {
      biasResults {
        category
        explanation
        input
        replacement
        feCategory
      }
      scanId
      errors {
        message
        isRetryable
      }
    }
  }
`
const SCAN = gql`
  mutation ($input: DetectBiasInput!) {
    detectBias(input: $input) {
      biasResults {
        category
        explanation
        input
        replacement
        feCategory
      }
      scanId
      errors {
        message
        isRetryable
      }
    }
  }
`

const SUBMIT_FEEDBACK = gql`
  mutation ($input: CreateSuggestionDecisionInput!) {
    createSuggestionDecision(input: $input) {
      success
    }
  }
`
const useBiasScanner = () => {
  const [detectBiasAi] = useMutation(SCAN_AI)
  const [detectBias] = useMutation(SCAN)
  const [submitFeedback] = useMutation(SUBMIT_FEEDBACK)
  return { detectBiasAi, detectBias, submitFeedback }
}

export default useBiasScanner
