const copyWithFormatByClassName = (className: string) => {
  const el: any = document.querySelector(className)

  const range = document.createRange()
  range.selectNodeContents(el)
  const selection: any = window.getSelection()
  selection.removeAllRanges()
  selection.addRange(range)

  const clipboardItem = new ClipboardItem({
    'text/html': new Blob([el.outerHTML], { type: 'text/html' }),
    'text/plain': new Blob([el.textContent || ''], {
      type: 'text/plain'
    })
  })

  navigator.clipboard.write([clipboardItem])
}

export default copyWithFormatByClassName
