import { Color } from '@mathison-inc/components'
import { Grid, Typography, Button, Box, CircularProgress } from '@mui/material'
import React, { useContext } from 'react'
import { Categories } from './contant'
import { BiasScannerContext } from 'context/BiasScannerContext'

const Suggestions = () => {
  const {
    isScanningStarted,
    isScanning,
    totalNumberOfHighlights,
    removeAllHighlights
  } = useContext(BiasScannerContext)
  return (
    <>
      <Grid container>
        <Grid item height='auto'>
          <Typography variant='h5' marginBottom='24px'>
            Suggestions ({totalNumberOfHighlights})
          </Typography>
        </Grid>
        <Grid item sx={{ marginLeft: 'auto' }} height='auto'>
          <Button
            disableRipple
            sx={{
              fontSize: '14px',
              textDecoration: 'underline',
              '&:hover': { textDecoration: 'underline' },
              padding: '4px 4px'
            }}
            onClick={() => {
              if (isScanning) return
              removeAllHighlights()
            }}
          >
            {isScanning && (
              <CircularProgress size={14} sx={{ marginRight: '8px' }} />
            )}
            Ignore all
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        {isScanningStarted ? (
          <>
            {Categories.map((item, index) => (
              <Box
                sx={{
                  border: '1px solid #E5E5E5',
                  padding: '16px 24px',
                  borderRadius: '8px',
                  backgroundColor: 'white',
                  marginBottom: '8px'
                }}
              >
                <Grid container>
                  <Grid item xs={1}>
                    <Box
                      sx={{
                        width: 4,
                        height: 28,
                        backgroundColor: item.labelColor
                      }}
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant='h6' color={Color.black}>
                      {item.label}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant='body2'
                      sx={{
                        width: '42px',
                        lineHeight: '28px',
                        backgroundColor: item.backgroundColor,
                        borderRadius: '8px',
                        textAlign: 'center'
                      }}
                      color={Color.black}
                    >
                      {
                        document.querySelectorAll(
                          `[data-category="${item.label}"]`
                        ).length
                      }
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            ))}
          </>
        ) : (
          <Box
            sx={{
              border: '1px solid #E5E5E5',
              padding: '24px',
              borderRadius: '8px'
            }}
          >
            <Typography variant='h6' color={Color.grey600}>
              No content scanned
            </Typography>
          </Box>
        )}
      </Grid>
    </>
  )
}

export default Suggestions
