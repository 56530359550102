import React from 'react'
import { Route, Routes } from 'react-router-dom'
import BiasScanner from 'pages/biasScanner'
import { BiasScannerProvider } from 'context/BiasScannerContext'

const PublicRoutes = () => {
  return (
    <div>
      <Routes>
        <Route
          path='/bias-scanner'
          element={
            <BiasScannerProvider>
              <BiasScanner />
            </BiasScannerProvider>
          }
        />
      </Routes>
    </div>
  )
}

export default PublicRoutes
